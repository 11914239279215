import Cross from '../../assets/cross';
import { Button, Drawer, Input } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../theme/jss-types';
import { trackingStyles } from './track-consignment.styles';
import ConsignmentTracking from '../consignments/consignment-tracking';
import { useTranslation } from 'react-i18next';

interface TrackConsignmentProps extends StylesProps<ReturnType<typeof trackingStyles>> {
    onClose: () => void;
    referenceNumber?: string;
    trackType?: string;
}

const TrackConsignment = (props: TrackConsignmentProps) => {
    const {
        classes,
        onClose,
        referenceNumber,
        trackType,
    } = props;
    const { t } = useTranslation();
    const [reference, setReference] = React.useState<string | undefined>(referenceNumber);
    const [toggle, setToggle] = React.useState<boolean>(true);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {(trackType && trackType === 'Customer Reference No')
                            ? t('track_customer_reference_number')
                            : t('track_consignment')}
                    </span>
                </div>
            </div>
        );
    };

    const renderTrackInputs = () => {
        return (
            <div className={classes.inputs}>
                <Input
                    value={reference}
                    placeholder={
                        (trackType && trackType === 'Customer Reference No')
                            ? t('track_customer_reference_number')
                            : t('referenceNumberList')
                    }
                    className={classes.input}
                    onChange={(e) => setReference(e.target.value)}
                    style={{ width: '74%' }}
                />
                <Button
                    type="primary"
                    onClick={() => setToggle(!toggle)}
                    disabled={!reference}
                >
                    {t('track')}
                </Button>
            </div>
        );
    };

    const renderAvailabilityText = () => {
        return (
            <div className={classes.trackText}>
                <div className={classes.flexRow}>
                    <span className={classes.availableText}>
                        {(trackType && trackType === 'Customer Reference No')
                            ? t('tracking_customer_reference_result_text')
                            : t('tracking_result_text')}
                    </span>
                    <span className={classes.reference}>{reference}</span>
                </div>
                {/* <Button type="link">View Details</Button> */}
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderAvailabilityText()}
            </div>
        );
    };

    const renderTracking = () => {
        return (
            <ConsignmentTracking
                dontShowTitle
                toggle={toggle}
                referenceNumber={reference}
                trackType={trackType}
            />
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderTrackInputs()}
            {renderBody()}
            {renderTracking()}
        </Drawer>
    );
};

export default withStyles(trackingStyles)(TrackConsignment);

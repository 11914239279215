import * as React from 'react';
import { WithStylesProps } from 'react-jss';
import Cross from '../../assets/cross';
import { Button, Drawer } from 'antd';
import PluginSourceFilter from '../common-filters/plugin-source-filter';
import ServiceTypeFilter from '../common-filters/service-type-filter';
import CustomerReferenceNumberFilter from 'components/common-filters/customer-reference-number-filter';
import StoreName from 'components/common-filters/store-name-filter';
import { HocOptions } from '../common/generic-hoc.types';
import { bindActionCreators } from 'redux';
import { ReduxStore } from '../../reducers/redux.types';
import {
    setFilters,
    resetFilters,
    applyFilters,
    clearSelection,
} from '../../actions/generic-action';
import { Buckets } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import { useTranslation } from 'react-i18next';
import { ThemeType } from 'theme/jss-types';
import {
    Master,
} from '../../types/master-data-types';


const styles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-picker': {
            width: 200,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 28,
            fontSize: 11,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    body: {
        padding: 24,
        paddingBottom: 8,
        width: '100%',
    },
    hr: {
        width: '100%',
        height: 1,
        backgroundColor: '#CCCCCC',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
        fontSize: 16,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
        width: 200,
    },
    moreFilter: {
        marginLeft: 16,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        color: theme.primaryColor,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelClass: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: 120,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 56,
    },
});

interface IProps extends WithStylesProps<ReturnType<typeof styles>> {
    onClose: () => void;
    setfilters: (bucketId: Buckets.PLUGIN, filters: any) => void;
    resetfilters: (bucketId: Buckets.PLUGIN) => void;
    applyfilters: (bucketId: Buckets.PLUGIN) => void;
    filters: Record<string, any>;
    resetVisible: boolean;
    clearselection: (bucketId: Buckets.PLUGIN) => void;
    master: Master;
}

const ExtraFilters = (props: IProps) => {
    const {
        classes,
        onClose,
        filters,
        resetfilters,
        setfilters,
        applyfilters,
        clearselection,
        resetVisible,
        master,
    } = props;

    const { t } = useTranslation();
    const config = master?.config || {};
    const [customerReferenceNumber, setCustomerReferenceNumber] = React.useState<string>(
        filters.customerReferenceNumber || '',
    );
    const serviceTypeOptions = config?.filter_metadata?.find((item) => {
        return item.id === 'bookingServiceTypeIdList';
    });

    const stringToArray = (str: string) => {
        return str.trim().split(/,\s*|\s+/).filter(Boolean);
    };

    const handleClose = (refresh = false) => {
        if (refresh) {
            applyfilters(Buckets.PLUGIN);
        } else {
            clearselection(Buckets.PLUGIN);
        }
        onClose();
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('more_filters')}
                    </span>
                </div>
                {resetVisible && (
                    <span
                        className={classes.resetFilter}
                        onClick={() => {
                            resetfilters(Buckets.PLUGIN);
                            onClose();
                        }}
                    >
                        {t('reset_filters')}
                    </span>
                )}
            </div>
        );
    };

    const onChangeFilter = (filter: string, value: any) => {
        const newFilters = {
            ...filters,
            [filter]: value,
        };
        if (filter === 'customerReferenceNumber') {
            setCustomerReferenceNumber(value);
            newFilters[filter] = stringToArray(value);
        }
        setfilters(Buckets.PLUGIN, newFilters);
    };

    const renderSourceFilter = () => {
        return (
            <div
                className={classes.row}
                key="source"
            >
                <PluginSourceFilter
                    value={filters.source}
                    onChange={((value) => onChangeFilter('source', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderServiceTypeFilter = () => {
        return (
            <div className={classes.row} key="serviceType">
                <ServiceTypeFilter
                    value={filters.serviceType}
                    serviceTypeOptions={serviceTypeOptions?.options}
                    onChange={((value) => onChangeFilter('serviceType', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderStoreNameFilter = () => {
        return (
            <div className={classes.row} key="storeName">
                <StoreName
                    value={filters.storeName}
                    onChange={((value) => onChangeFilter('storeName', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderCustomerReferenceNumberFilter = () => {
        return (
            <div className={classes.row} key="customerReferenceNumber">
                <CustomerReferenceNumberFilter
                    value={customerReferenceNumber}
                    onChange={((value) => onChangeFilter('customerReferenceNumber', value))}
                    labelClass={classes.labelClass}
                />
            </div>
        );
    };

    const renderFilter = (filter: string) => {
        switch (filter) {
            case 'customerReferenceNumber': return renderCustomerReferenceNumberFilter();
            case 'serviceType': return renderServiceTypeFilter();
            case 'storeName': return renderStoreNameFilter();
            case 'source':
            default: return renderSourceFilter();
        }
    };

    const renderFilterList = () => {
        const filterList = ['customerReferenceNumber', 'source', 'serviceType', 'storeName'];
        return filterList.map((filter) => renderFilter(filter));
    };

    const renderBody = () => {
        return (
            <div className={classes.body}>
                {renderFilterList()}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <Button
                    onClick={() => handleClose(true)}
                    type="primary"
                    style={{ marginLeft: 10, marginRight: 20 }}
                >
                    {t('apply_filters')}
                </Button>
                <Button
                    onClick={() => handleClose()}
                    type="dashed"
                >
                    {t('cancel')}
                </Button>
            </div>
        );
    };

    return (
        <Drawer
            className={classes.main}
            visible
            width="30%"
            title={renderHeader()}
            onClose={() => handleClose()}
            closable={false}
        >
            {renderBody()}
            {renderLine()}
            {renderFooter()}
        </Drawer>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic, master } = state;
    const { PLUGIN } = generic;
    const { filters } = PLUGIN;
    return {
        filters,
        master,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        resetfilters: resetFilters,
        applyfilters: applyFilters,
        clearselection: clearSelection,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
};

const PluginFilters = GenericHoc(hocConfig)(ExtraFilters);
export default PluginFilters;

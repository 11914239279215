import * as React from 'react';
import { pickupStyles } from './pickup.styles';
// import Paginator from '../../common/paginator';
import moment from 'moment';
import {
    Button,
    DatePicker,
    message,
    Popover,
    Table,
} from 'antd';
import {
    InfoCircleOutlined,
    SettingOutlined,
//   PrinterOutlined,
} from '@ant-design/icons';
import {
    bulkPrint,
} from '../../../network/consignments.api';
// import Helper from '../../../library/Helper';
import FilterBlue from '../../../assets/filter-blue';
import { HocOptions } from '../../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { downloadPickups, fetchSummaryPickups } from '../../../network/pickup.api';
import PickupFilters from './pickup-filters';
import { BucketKeys, ReduxStore } from '../../../reducers/redux.types';
import { bindActionCreators } from 'redux';
import {
    applyFilters,
    setFilters,
} from '../../../actions/generic-action';
import { Buckets } from '../../../library/Types';
import { loadPickups } from '../../../actions/pickup-actions';
import GenericHoc from '../../common/generic-hoc';
import { getDownloadsRoute } from '../../../routing/routing-helper';
import { RouteChildrenProps } from 'react-router';
import { NAVBAR_HEIGHT } from 'library/globals';
import {
    Master,
    PickupBucketKeys,
    PickupColumn,
    PickupColumns,
} from '../../../types/master-data-types';
import PickupSettings from '../../settings/pickup-settings';
import { uniqBy } from 'lodash';
import { PickupBuckets } from './pickups.constants';
import { useTranslation } from 'react-i18next';
import GalleryModal from '../../common/gallery-modal';

interface ConsignmentProps
    extends StylesProps<ReturnType<typeof pickupStyles>>,
    RouteChildrenProps {
    master: Master;
    setfilters: (bucketId: BucketKeys, filters: any) => void;
    applyfilters: (bucketId: BucketKeys) => void;
    filters: Record<string, any>;
    loading: boolean;
    loadpickups: () => void;
    pickups: any[];
    defaultFilters: Record<string, any>;
    uiTheme: ThemeType;
}
interface GalleryModalData {
    images: string[];
    videos: string[];
}

const {
    useState,
    useEffect,
} = React;

const PickupPage = (props: ConsignmentProps) => {
    const [bucketSelected, setBucketSelected] = useState<PickupBucketKeys>('customer_portal_all');
    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);
    const [summary, setSummary] = useState<any>({});
    const [downloading, setDownloading] = useState<boolean>(false);
    const [bulkPrinting, setBulkPrinting] = useState<boolean>(false);
    const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [galleryModalData, setGalleryModalData] = React.useState<GalleryModalData>({ images: [], videos: [] });

    const {
        classes,
        master,
        filters,
        setfilters,
        applyfilters,
        loadpickups,
        pickups,
        loading,
        history,
        defaultFilters,
        uiTheme,
    } = props;
    const { t } = useTranslation();

    const { config } = master;

    const loadSummary = async () => {
        const response = await fetchSummaryPickups({
            dateFilterColumn: 'created_at',
            startDate: moment(filters.fromDate).format('YYYY-MM-DD'),
            endDate: moment(filters.toDate).format('YYYY-MM-DD'),
            bucketIds: Object.keys(PickupBuckets),
            isRiderAssigned: filters.isRiderAssigned === 'ALL' ? undefined : filters.isRiderAssigned === 'ASSIGNED',
            loadType: filters.courierType === 'ALL' ? undefined : filters.courierType,
        });
        if (response.isSuccess) {
            setSummary(response?.data || {});
        } else {
            message.error(response.errorMessage);
            setSummary({});
        }
    };
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const showBucketCount = master?.config?.customer_portal_config?.fetch_customer_portal_bucket_count;

    useEffect(() => {
        loadpickups();
        loadSummary();
    }, [filters, toggle]);

    const renderText = (text: string | number | null) => {
        if (!text || text === null) {
            return <div className={classes.cellNa}>—na—</div>;
        }
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };
    const renderGallery = (imageArray: any[], videoArray: any[]) => {
        if (!imageArray || !videoArray) return 'Not Available';
        return (
            <a onClick={() => {
                setGalleryModalData({
                    images: imageArray,
                    videos: videoArray,
                });
                setIsGalleryModalVisible(true);
            }}
            >
                View
            </a>
        );
    };

    // const handlePrintLabel = (fileBuffer: any, fileName: string) => {
    //   if (fileBuffer.isSuccess) {
    //     const name = fileBuffer.filename || fileName;
    //     Helper.downloadFileData(
    //       fileBuffer.data,
    //       name,
    //       true,
    //     );
    //     message.success('Success');
    //   } else {
    //     message.error(fileBuffer.errorMessage);
    //   }
    // };

    // const handlePrint = async (row: any) => {
    //   const response = await bulkPrint({
    //     referenceNumbers: [row.reference_number],
    //   });
    //   handlePrintLabel(response, 'Print');
    // };

    // const renderDropdown = (row: any) => {
    //   return (
    //     <PrinterOutlined onClick={() => handlePrint(row)} />
    //   );
    // };

    const renderDestinationDetailsTable = (data: any) => {
        const columns = [
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: 'Units',
                dataIndex: 'units',
                key: 'units',
            },
        ];
        return (
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                style={{
                    margin: '0 10px',
                }}
            />
        );
    };

    const renderDestinationDetails = (row: any) => {
        return (
            <Popover
                trigger="click"
                title="Destination Details"
                content={() => renderDestinationDetailsTable([row.destinationDetails])}
            >
                <InfoCircleOutlined />
            </Popover>
        );
    };

    const getAddress = (address: any) => {
        let result = address.name;
        result += ', ';
        result += address.addressLine1;
        result += ', ';
        result += address.addressLine2;
        result += ', ';
        result += address.cityName;
        result += ', ';
        result += address.stateName;
        return result;
    };

    const renderColumn = (text: any, row: any, column: string) => {
        switch (column) {
            case 'task_reference_number':
                return renderText(row.pickupId);
            case 'total_weight':
                return renderText(row.totalWeight);
            case 'total_volume':
                return renderText(row.totalVolume);
            case 'length':
                return renderText(row.length);
            case 'width':
                return renderText(row.width);
            case 'height':
                return renderText(row.height);
            case 'pickup_address':
                return renderText(getAddress(row.pickupAddress));
            case 'pickup_date':
                return renderText(row.timeSlotStart ? moment(row.timeSlotStart).format('DD MMM, YYYY') : null);
            case 'pickup_slot':
                return renderText(
                    row.timeSlotStart
                        ? `${moment(row.timeSlotStart).format('hh:mm')}-${moment(row.timeSlotEnd).format('hh:mm')}` : null,
                );
            case 'closing_time':
                return renderText(row.closingTimeString);
            case 'number_of_shipments':
                return renderText(row.totalItems);
            case 'item_type':
                return renderText(row.courierType);
            case 'pickup_task_status':
                return renderText(row.pickupTaskStatus);
            case 'is_rider_assigned':
                return renderText(row.isRiderAssigned ? 'Yes' : 'No');
            case 'waybill_type':
                return renderText(row.waybillType);
            case 'destination_details':
                return renderDestinationDetails(row);
            case 'failure_reason':
                return renderText(row.pickupFailureReason);
            case 'pickup_created_at':
                return renderText(row.pickupTaskCreationTimestamp);
            case 'pickup_pincode':
                return renderText(row.pickupAddress.pincode);
            case 'pickup_city':
                return renderText(row.pickupAddress.cityName);
            case 'pickup_phone_number':
                return renderText(row.pickupAddress.phone);
            case 'completion_time':
                return renderText(row.completionTime);
            case 'pickup_type':
                return renderText(row.pickupType);
            case 'proof_of_pickup_image':
                if (!row
                    || !row.taskProofOfPickupImage
                    || !Array.isArray(row.taskProofOfPickupImageList)
                    || !row.taskProofOfPickupImageList.length) {
                    return renderText('Not Available');
                }
                return renderGallery([row.taskProofOfPickupImage, ...row.taskProofOfPickupImageList], []);
            default:
                return renderText(text);
        }
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'proof_of_pickup_image':
            case 'item_type': return 100;
            case 'completion_time':
            case 'pickup_created_at':
            case 'pickup_address':
                return 200;
            default: return 80;
        }
    };

    const getFixed = (column: string) => {
        switch (column) {
            case 'actions': return 'right';
            case 'task_reference_number': return 'left';
            default: return undefined;
        }
    };

    const getColumns = (): any[] => {
        const pickup_column_list_by_bucket: PickupColumns<any> = config?.pickup_column_list_by_bucket || {};
        const columnLabels = [...(pickup_column_list_by_bucket?.pickup_all || [])];
        columnLabels.push({
            pretty_name: t('actions'),
            column_id: 'actions',
        });

        const columns: any = columnLabels.map((column: PickupColumn) => {
            return {
                key: column.column_id,
                title: column.column_id === 'completion_time' ? t('completion_time_pickup') : t(column.column_id),
                dataIndex: column.column_id,
                width: getWidth(column.column_id),
                ellipsis: true,
                fixed: getFixed(column.column_id),
                render: (text: string, row: any) => renderColumn(text, row, column.column_id),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                className={classes.table}
                dataSource={pickups || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 150px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const handleBucketChange = (bucket: PickupBucketKeys) => {
        if (bucket === bucketSelected) {
            return;
        }
        setfilters(Buckets.PICKUPS, {
            ...filters,
            status: bucket === 'customer_portal_all' ? undefined : bucket.substring(16),
        });
        applyfilters(Buckets.PICKUPS);
        setBucketSelected(bucket);
    };

    const renderBucket = (bucket: PickupBucketKeys) => {
        let bucketClass = classes.bucket;
        let countClass = classes.count;
        if (bucket === bucketSelected) {
            bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            countClass = [classes.count, classes.countSelected].join(' ');
        }
        return (
            <div
                key={bucket}
                className={bucketClass}
                onClick={() => handleBucketChange(bucket)}
            >
                <span
                    className={classes.bucketName}
                >
                    {t(bucket)}
                </span>
                {
                    showBucketCount
                        ? (<span className={countClass}>{summary[bucket] || 0}</span>)
                        : null
                }
            </div>
        );
    };

    const renderBuckets = () => {
        return (
            <div className={classes.bucketRow}>
                {Object.keys(PickupBuckets)
                    .map((bucket) => renderBucket(bucket as PickupBucketKeys))}
            </div>
        );
    };

    const renderDateField = (key: 'fromDate' | 'toDate') => {
        const name = key === 'fromDate' ? 'From' : 'To';
        const inputName = t(`${name}_date`);
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={moment(filters[key])}
                    allowClear={false}
                    placeholder={`Select ${inputName}`}
                    onChange={(value) => {
                        const newFilters = {
                            ...filters,
                        };
                        if (key === 'fromDate') {
                            if (moment(filters.toDate).diff(value, 'days') > 30) {
                                const toDate = moment(value).add(30, 'd');
                                newFilters.toDate = toDate.unix() * 1000;
                                newFilters.fromDate = moment(value).unix() * 1000;
                            } else if (moment(filters.toDate).diff(value, 'days') < 0) {
                                newFilters.toDate = moment(value).unix() * 1000;
                                newFilters.fromDate = moment(value).unix() * 1000;
                            } else {
                                newFilters[key] = moment(value).unix() * 1000;
                            }
                        } else {
                            newFilters[key] = moment(value).unix() * 1000;
                        }
                        setfilters(Buckets.PICKUPS, newFilters);
                        applyfilters(Buckets.PICKUPS);
                    }}
                />
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        const dateKeys = ['fromDate', 'toDate'];
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (dateKeys.includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const renderMoreFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        return (
            <div
                className={classes.moreFilter}
                onClick={() => setMoreFiltersVisible(true)}
                style={{
                    color: filterApplied ? uiTheme.primaryColor : '#111111',
                    fontWeight: filterApplied ? 600 : 500,
                }}
            >
                <FilterBlue style={{ marginRight: 2 }} />
                <span>{t('more_filters')}</span>
            </div>
        );
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setfilters(Buckets.PICKUPS, {
                        ...defaultFilters,
                        fromDate: filters.fromDate,
                        toDate: filters.toDate,
                    });
                    applyfilters(Buckets.PICKUPS);
                    setBucketSelected('customer_portal_all');
                }}
            >
                {t('reset_filters')}
            </div>
        );
    };

    const renderFilter = () => {
        return (
            <div className={classes.row}>
                {renderMoreFiltersOption()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderDateField('fromDate')}
                {renderDateField('toDate')}
                {renderFilter()}
            </div>
        );
    };

    const handleSettingsClose = (refresh?: boolean) => {
        setSettingsVisible(false);
        if (refresh) {
            setToggle(!toggle);
        }
    };
    const renderSettingsModal = () => {
        if (!settingsVisible) {
            return null;
        }
        const options = uniqBy(config?.full_pickup_column_list || [], 'column_id');
        const columns: Record<string, PickupColumn> = {};
        options.forEach((item) => {
            columns[item.column_id] = item;
        });
        return (
            <PickupSettings
                bucketSelected="pickup_all"
                filterObject={{ columns }}
                onClose={(refresh?: boolean) => handleSettingsClose(refresh)}
            />
        );
    };

    const getFilterValue = (value: string) => {
        if (value === 'ALL') {
            return undefined;
        }
        return value;
    };

    const getRiderAssigned = (value: string) => {
        if (value === 'ALL') {
            return undefined;
        }
        return value === 'ASSIGNED';
    };

    const handleDownload = async () => {
        const dateFormat = 'DD/MM/YYYY';
        setDownloading(true);
        const response = await downloadPickups({
            clientName: 'ALL',
            courierName: 'ALL',
            fromDate: moment(filters.fromDate).format(dateFormat),
            toDate: moment(filters.toDate).format(dateFormat),
            sortBy: filters.sortBy,
            courierType: getFilterValue(filters.courierType),
            isRiderAssigned: getRiderAssigned(filters.isRiderAssigned),
            status: getFilterValue(filters.status),
        });
        if (response.isSuccess) {
            message.success('Downloaded Successfully');
            history.push(getDownloadsRoute());
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };

    const renderDownload = () => {
        return (
            <Button
                type="ghost"
                loading={downloading}
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                {t('download')}
            </Button>
        );
    };

    const handleBulkPrint = async () => {
        setBulkPrinting(true);
        const response = await bulkPrint({});
        if (response.isSuccess) {
            message.success('Success');
        } else {
            message.error(response.errorMessage);
        }
        setBulkPrinting(false);
    };

    const renderPrint = () => {
        return (
            <Button
                type="primary"
                style={{ display: 'none' }}
                className={classes.downloadBtn}
                loading={bulkPrinting}
                onClick={handleBulkPrint}
            >
                {t('bulk_print')}
            </Button>
        );
    };

    // const renderPaginator = () => {
    //   return (
    //     <Paginator
    //       currentPageNumber={1}
    //       isNextPresent={false}
    //       onNextClick={() => { }}
    //       onPrevClick={() => { }}
    //     />
    //   );
    // };

    const renderSettings = () => {
        return (
            <SettingOutlined
                className={classes.settingIcon}
                onClick={() => setSettingsVisible(true)}
            />
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderDownload()}
                {renderPrint()}
                {renderSettings()}
                {/* {renderPaginator()} */}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderBuckets()}
                {renderExtraFilters()}
            </div>
        );
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <PickupFilters
                resetVisible={isMoreFilterAdded()}
                onClose={() => setMoreFiltersVisible(false)}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderMoreFilters()}
            {renderSettingsModal()}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic, master, uiTheme } = state;
    const { PICKUPS } = generic;
    const {
        appliedFilters,
        loading,
        data,
        defaultFilters,
    } = PICKUPS;
    return {
        master,
        loading,
        defaultFilters,
        pickups: data,
        filters: appliedFilters,
        uiTheme,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        applyfilters: applyFilters,
        loadpickups: loadPickups,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: pickupStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const Pickups = GenericHoc(hocConfig)(PickupPage);
export default Pickups;

import { commonStyleSheet } from 'library/common-styles';
import { ThemeType } from 'theme/jss-types';

export const createPickupStyles = (theme: ThemeType) => ({
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#F1EEE7',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        '& .ant-picker': {
            boxSizing: 'border-box',
            height: 32,
            width: 250,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-radio-wrapper': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            margin: '4px 24px 4px 0px',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    addressName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    newButton: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: `${theme.primaryColor} !important`,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    newButtonDisabled: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: '#EDEDED !important',
        border: '1px solid #EDEDED',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    contentType: {
        width: '50%',
        justifyContent: 'space-between',
        ...commonStyleSheet(theme).flexRow,
    },
    formItem: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginBottom: 16,
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    weight: {
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        '& .ant-input-group': {
            width: '100%',
            display: 'flex',
        },
        '& .ant-input': {
            marginRight: 0,
            width: '100%',
        },
        '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            marginRight: 0,
            boxShadow: 'none',
            border: 'none',
        },
        '& .ant-input-group-addon > .ant-input:first-child': {
            width: '70%',
        },
        '& .ant-input-group-addon:last-child': {
            width: '30%',
            height: 32,
        },
        '& .ant-input-group-addon': {
            backgroundColor: '#fafafa',
            border: '1px solid #999',

        },
        '& .ant-select-selector': {
            padding: 0,
            margin: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            fontWeight: 600,
        },
    },
    box: {
        padding: '32px 20px',
        boxSizing: 'border-box',
        border: '1px solid #F7F7F7',
        borderRadius: 4,
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #CBC2B0',
        width: '100%',
        display: 'flex',
    },
    boxTitle: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '18%',
    },
    boxIcon: {
        width: '100%',
        height: '30px',
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        width: '30%',
        '& .ant-form-item': {
            marginBottom: 12,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '90%',
    },
    upcoming: {
        height: 106,
        width: '30%',
        borderRadius: 4,
        backgroundColor: '#FFF1AC',
        padding: '18px 24px',
        display: 'flex',
        flexDirection: 'column',
    },
    upcomingText: {
        color: '#111111',
        fontSize: 14,
    },
    timeleft: {
        color: '#111111',
        fontSize: 18,
        fontWeight: 600,
        marginTop: 8,
    },
    datetime: {
        color: '#111111',
        fontSize: 12,
    },
    searchAddress: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    searchText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    line: {
        height: 1,
        margin: '12px 0px',
        width: '100%',
        backgroundColor: '#EDEDED',
    },
    boxFields: {
        width: '80%',
    },
    addressList: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        maxHeight: '40vh',
    },
    addressDetails: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 16,
    },
    addressLine: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        width: '98%',
        borderRadius: 4,
        backgroundColor: '#FCFCFC',
        margin: '4px 0px',
        cursor: 'pointer',
    },
    addressLineSelected: {
        backgroundColor: '#E7F5FF',
    },
    checkbox: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        '& .ant-checkbox-checked .ant-checkbox-inner': {
            backgroundColor: theme.primaryColor,
            borderColor: theme.primaryColor,
        },
        '& .ant-checkbox + span': {
            width: '100%',
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '82%',
    },
    itemTypeField: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    dimentionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '17.5%',
    },
    dimensions: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        '& .ant-form-item': {
            marginBottom: 12,
        },
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
});

import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

export const locationSearchStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 16,
            position: 'fixed',
            width: '100%',
            zIndex: 10,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-btn-link': {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            fontSize: 12,
            color: theme.primaryColor,
        },
        '& .ant-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
        },
        marginBottom: 100,
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: 0,
    },
    addText: {
        ...commonStyleSheet(theme).flexRow,
        color: '#000000',
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontSize: 20,
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
    locationField: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: '10px',
        marginTop: 16,
    },
    locationLabel: {
        width: '60px',
        fontSize: 14,
        fontWeight: 600,
    },
    locationSelect: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
    },
    PincodeText: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        '& input:disabled': {
            color: '#696969',
        },
    },
    optionStyle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    optionInfo: {
        color: 'grey',
        fontSize: '10px',
    },
    copyButtonDiv: {
        padding: '5px',
        margin: '0 10px',
    },
    copyButton: {
        cursor: 'pointer',
        fontSize: '16px',
    },
    copyButtonDisabled: {
        color: '#cccccc !important',
    },
});

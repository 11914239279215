/* eslint-disable max-len */
import { DownloadOutlined } from '@ant-design/icons';
import { message, Select, Table } from 'antd';
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { getServiceList, getVirtualInventory } from '../../network/virtual-inventory';
import { getChildList } from 'network/consignments.api';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { ExcelColumn, Master } from '../../types/master-data-types';
import { VirtualInventoryColumns } from './virtual-inventory.constants';
// import Paginator from '../common/paginator';
import moment from 'moment';
import { IdName, Inventory } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import { NAVBAR_HEIGHT } from 'library/globals';
import { useTranslation } from 'react-i18next';

const styles = (theme: ThemeType) => ({
    main: {
        backgroundColor: theme.backgroundColor,
        '& .ant-table-tbody > tr > td': {
            padding: 16,
        },
        padding: '0px 8px',
        '& .ant-table-thead > tr > th': {
            padding: '6px 16px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            width: 270,
            height: 32,
            borderRadius: 16,
            border: '1px solid #999999',
            fontSize: 12,
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    extraFilters: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        margin: '4px 0px',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
        fontFamily: 'Open Sans',
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    filterOptions: {
        fontFamily: 'Open Sans',
    },
});

interface VirtualInventoryProps extends StylesProps<ReturnType<typeof styles>> {
    master: Master,
}

const VirtualInventory = (props: VirtualInventoryProps) => {
    const { classes, master } = props;
    const [virtualList, setVirtualList] = React.useState<Inventory[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [serviceType, setServiceType] = React.useState<string[]>();
    const [services, setServices] = React.useState<IdName[]>([]);
    const [childClients, setChildClients] = React.useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(window.localStorage.getItem('childCustomerId'));
    const [selectedClientCode, setSelectedClientCode] = React.useState<any>(window.localStorage.getItem('childCustomerCode'));
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const { t } = useTranslation();

    const allow_child_login_on_parent = master?.parts_to_show?.allow_child_login_on_parent || false;

    const loadData = async (id: any) => {
        setLoading(true);
        const response = await getVirtualInventory({
            serviceType,
            clientId: id || window.localStorage.getItem('childCustomerId'),
        });
        if (response.isSuccess) {
            setVirtualList(response?.data || []);
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const loadServices = async () => {
        const response = await getServiceList({
        });
        setServices(response?.data || []);
    };

    const loadChildData = async () => {
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0].customer_id
            : window.localStorage.getItem('childCustomerId');
        setSelectedCustomer(customer);
    };

    React.useEffect(() => {
        loadServices();
    }, []);

    React.useEffect(() => {
        loadData(selectedCustomer);
    }, []);

    React.useEffect(() => {
        loadChildData();
    }, []);

    const renderOption = (service: IdName) => {
        return (
            <Select.Option
                key={service.name}
                value={service.name}
                className={classes.filterOptions}
            >
                {service.name}
            </Select.Option>
        );
    };

    const renderFilter = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {t('service_type')}
                </span>
                <Select
                    allowClear
                    value={serviceType}
                    placeholder={t('select_service_type')}
                    onChange={(value) => setServiceType(value)}
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    maxTagCount="responsive"
                    className={classes.filterOptions}
                >
                    {services.map((service) => renderOption(service))}
                </Select>
            </div>
        );
    };

    const handleCustomerChange = (val: any, options: any) => {
        if (options?.label) {
            setSelectedClientCode(options.label);
        }
        loadData(val);
    };

    const renderChildDropdown = () => {
        if (!allow_child_login_on_parent) {
            return null;
        }
        const childList = childClients?.map((item: any) => {
            return {
                value: item.customer_id,
                label: item.customer_code,
            };
        });
        return (
            <div style={{ margin: '5px' }}>
                <span className={classes.filterText}>
                    Entity Code
                </span>
                <Select
                    placeholder="Select Account"
                    dropdownMatchSelectWidth={false}
                    defaultValue={selectedCustomer}
                    onChange={handleCustomerChange}
                    showSearch
                    allowClear
                    options={childList}
                    filterOption={(query, option: any) => {
                        return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                    }}
                />
            </div>
        );
    };
    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderFilter()}
                {renderChildDropdown()}
                {/* <Paginator
          currentPageNumber={1}
          isNextPresent={false}
          onNextClick={() => { }}
          onPrevClick={() => { }}
        /> */}
            </div>
        );
    };

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (text: any, column: ExcelColumn, record: any) => {
        if (column.column_id === 'action') {
            return (
                <DownloadOutlined />
            );
        }
        if (column.column_id === 'createdAt') {
            return renderText(moment(text).format('DD MMM, YYYY'));
        }
        if (column.column_id === 'serviceType') {
            return renderText(text.join(', '));
        }
        if (column.column_id === 'clientId') {
            let userCode = window.localStorage.getItem('userCode') || '';
            if (allow_child_login_on_parent) {
                userCode = selectedClientCode || '';
            }
            return renderText(userCode);
        }
        if (column.column_id === 'checkDigitLogic') {
            if (record.enableCheckDigitCalculation) {
                switch (record?.checkDigitLogic?.operator) {
                    case '%':
                        return `MOD ${record?.checkDigitLogic?.operand}`;
                    default:
                        return 'NA';
                }
            }
            return 'NA';
        }
        if (column.column_id === 'type') {
            return renderText(text?.toUpperCase());
        }
        return renderText(text);
    };

    const getColumns = (): any[] => {
        const columns: any = VirtualInventoryColumns.map((column: ExcelColumn) => {
            return {
                key: column.column_id,
                title: t(column.pretty_name),
                dataIndex: column.column_id,
                ellipsis: true,
                bordered: false,
                width: column.width,
                render: (text: string, record: any) => renderColumn(text, column, record),
            };
        });
        return columns;
    };

    const renderTable = () => {
        let filteredList = virtualList;
        if (serviceType && serviceType.length) {
            filteredList = virtualList.filter((item) => {
                let isAvailable = false;
                serviceType.forEach((service) => {
                    isAvailable = isAvailable || item.serviceType.includes(service);
                });
                return isAvailable;
            });
        }
        return (
            <Table
                bordered={false}
                loading={loading}
                pagination={false}
                className={classes.table}
                rowKey={(row: any) => row.id}
                columns={getColumns()}
                dataSource={filteredList}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderExtraFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        master: state.master,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(VirtualInventory);

import {
    Button,
    Drawer,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Select,
} from 'antd';
import Cross from '../../../assets/cross';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../../theme/jss-types';
import { itemDetailsStyles } from '../single-consignment.styles';
import { itemFormFields } from '../create-modal.constants';
import { FormField, InputTypes } from '../create-consignment.types';
import TextArea from 'antd/lib/input/TextArea';
import { get, merge } from 'lodash';
import { formRules } from 'library/constants';
import { LabelValue } from 'library/Types';
import { useTranslation } from 'react-i18next';

interface PieceDetails {
    key: any;
    height?: any;
    length?: any;
    numberOfPieces?: any;
    unit?: any;
    weight?: any;
    weightUnit?: any;
    dimensionsUnit?: any;
    width?: any;
    declaredValue?: any;
    description?: any;
    items?: Record<any, any>;
}

interface StepTwoProps extends StylesProps<ReturnType<typeof itemDetailsStyles>> {
    selectedItem: any;
    onClose: any;
    pieces: any;
    formData: any,
    setFormData: any;
    selectedPieceKey: any;
    form: FormInstance;
    setPieces: any;
    mandatoryItemDetails: Record<any, boolean>;
    itemDetailsToShow: Record<any, boolean>;
    countryList: LabelValue[];
}

const ItemDetails = (props: StepTwoProps) => {
    const {
        classes,
        selectedItem,
        onClose,
        pieces,
        formData,
        setFormData,
        selectedPieceKey,
        setPieces,
        form,
        mandatoryItemDetails,
        itemDetailsToShow,
        countryList,
    } = props;

    const {
        HSNCode,
        OriginOfGoods,
        SKU_Number,
        ATE_Number,
        Prod_URL,
        Item_Rate,
        Duty_Value,
        Gst_Value,
        GST_Percentage,
        Prod_dead_weight,
        Prod_height,
        Prod_length,
        Prod_width,
        Composition,
        Prod_Type,
        RODTEP_Scheme,
        Item_Size,
        Cess_Value,
        no_of_items,
        item_Description,
    } = itemFormFields;
    const { t, i18n } = useTranslation();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const prefillData = () => {
        const itemPrefilData = get(pieces[selectedPieceKey]?.items || {}, selectedItem, {});
        form.setFieldsValue(itemPrefilData);
    };

    const autoCalculatedValues = () => {
        const formValue = form.getFieldsValue();
        const quantity = formValue.pieces[selectedPieceKey].items[selectedItem].numberOfItems;
        const itemValue = formValue.pieces[selectedPieceKey].items[selectedItem].itemValue;
        const gstPercentage = formValue.pieces[selectedPieceKey].items[selectedItem].gstPercentage;
        const dutyValue = quantity && itemValue ? quantity * itemValue : undefined;
        const gstValue = gstPercentage && dutyValue ? gstPercentage * dutyValue * 0.01 : undefined;
        const obj = {
            pieces: {
                [selectedPieceKey]: {
                    items: {
                        [selectedItem]: {
                            dutyValue: dutyValue ? Number(dutyValue.toFixed(3)) : dutyValue,
                            gstValue: gstValue ? Number(gstValue.toFixed(3)) : gstValue,
                        },
                    },
                },
            },
        };
        form.setFieldsValue(obj);
    };

    React.useEffect(() => {
        prefillData();
        autoCalculatedValues();
    }, []);

    const getFormRules = (field: FormField) => {
        const rules = [];
        const IntegerOnlyKeys: string[] = [no_of_items.key];
        if (mandatoryItemDetails[field.key]) rules.push(formRuleRequired);
        if (IntegerOnlyKeys.includes(field.key)) rules.push(formRules.number);
        return rules;
    };

    const renderInput = (field: FormField) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', selectedItem, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
        >
            <Input
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
                width={field.width}
            />
        </Form.Item>
    );

    const renderInputNumber = (field: FormField) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', selectedItem, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={getFormRules(field)}
        >
            <InputNumber
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                width={field.width}
                disabled={field.disabled}
                onChange={
                    ['itemValue', 'gstPercentage', 'numberOfItems'].includes(field.key)
                        ? () => autoCalculatedValues() : undefined
                }
            />
        </Form.Item>
    );

    const renderTextArea = (field: FormField) => (
        <Form.Item
            name={['pieces', selectedPieceKey, 'items', selectedItem, field.key]}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
        >
            <TextArea
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
            />
        </Form.Item>
    );

    const renderFormItem = (field: FormField | undefined) => {
        if (!field) {
            return (
                <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }} />
            );
        }
        if (!itemDetailsToShow[field.key]) return null;
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        {renderInput(field)}
                    </div>
                );
            case InputTypes.Number:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        {renderInputNumber(field)}
                    </div>
                );
            case InputTypes.Textarea:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        {renderTextArea(field)}
                    </div>
                );
            case InputTypes.Radio:
                // eslint-disable-next-line no-case-declarations
                const options = field.options ? [...field.options, ...countryList] : countryList;
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {mandatoryItemDetails[field.key] ? '*' : ''}
                        </div>
                        <Form.Item
                            name={['pieces', selectedPieceKey, 'items', selectedItem, field.key]}
                            initialValue={field.defaultValue}
                            valuePropName={field.valuePropName}
                            rules={mandatoryItemDetails[field.key] ? [formRuleRequired] : undefined}
                        >
                            <Select
                                showSearch
                                options={options}
                                style={{ width: field.width }}
                            />
                        </Form.Item>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderButtonSave = () => {
        return (
            <Form.Item>
                <Button
                    type="primary"
                    onClick={async () => {
                        const check: any[] = [];
                        Object.keys(mandatoryItemDetails).forEach((fieldKey) => {
                            if (mandatoryItemDetails[fieldKey]) {
                                check.push(['pieces', selectedPieceKey, 'items', selectedItem, fieldKey]);
                            }
                        });
                        await form.validateFields(check);
                        const updatedData = merge(formData, form.getFieldsValue());
                        setFormData(updatedData);
                        const updatedPieces: Record<any, PieceDetails> = {};
                        Object.keys(updatedData.pieces).forEach((key) => {
                            updatedPieces[key] = {
                                ...updatedData.pieces[key],
                                key,
                            };
                        });
                        setPieces(updatedPieces);
                        onClose();
                    }}
                    style={{ marginRight: 16 }}
                    htmlType="submit"
                    // loading={creating}
                >
                    Save
                </Button>
            </Form.Item>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross
                        onClick={() => onClose()}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>Add Item</span>
                </div>
                {renderButtonSave()}
            </div>
        );
    };

    return (
        <div>
            <Drawer
                visible
                width="40%"
                title={renderHeader()}
                height="100vh"
                // onClose={onClose}
                className={classes.main}
                closable={false}
            >
                <Form
                    form={form}
                >
                    {renderFormItem(HSNCode)}
                    {renderFormItem(OriginOfGoods)}
                    {renderFormItem(item_Description)}
                    {renderFormItem(no_of_items)}
                    {renderFormItem(Item_Rate)}
                    {renderFormItem(Duty_Value)}
                    {renderFormItem(GST_Percentage)}
                    {renderFormItem(Gst_Value)}
                    {renderFormItem(Cess_Value)}
                    {renderFormItem(SKU_Number)}
                    {renderFormItem(ATE_Number)}
                    {renderFormItem(Prod_URL)}
                    {renderFormItem(Prod_dead_weight)}
                    {renderFormItem(Prod_height)}
                    {renderFormItem(Prod_length)}
                    {renderFormItem(Prod_width)}
                    {renderFormItem(Composition)}
                    {renderFormItem(Prod_Type)}
                    {renderFormItem(RODTEP_Scheme)}
                    {renderFormItem(Item_Size)}
                </Form>
            </Drawer>
        </div>
    );
};

export default withStyles(itemDetailsStyles)(ItemDetails);

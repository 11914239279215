import * as React from 'react';
import { StylesProps } from '../../theme/jss-types';
import { HocOptions } from '../common/generic-hoc.types';
import { settingsStyles } from './settings.styles';
import { SettingPages } from './settings.constants';
import { Routes } from '../../library/constants';
import { RouteChildrenProps } from 'react-router';
import {
    getHeaderMappingRoute,
    getProductMappingRoute,
    getSettingsAccountRoute,
    getSettingsChannelRoute,
    getPieceHeaderMappingRoute,
} from '../../routing/routing-helper';
import GenericHoc from '../common/generic-hoc';
import AccountSettings from './account-settings';
import ChannelIntegration from './channel-integration';
import ProductMapping from './product-mapping';
import HeaderMapping from './header-mapping';
import { ReduxStore } from 'reducers/redux.types';
import { Master } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';

interface SettingsProps extends StylesProps<ReturnType<typeof settingsStyles>>, RouteChildrenProps {
    master: Master;
}

const SettingsIframe = (props: SettingsProps) => {
    const {
        classes,
        history,
        master,
    } = props;
    const { t } = useTranslation();
    const addtionalConfig = master?.config?.customer_portal_ops_config?.additional_config;
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const enable_shopify_integration = master?.parts_to_show?.enable_shopify_integration || false;
    let showHeaderMapping = master?.config?.customer_portal_config?.show_header_mapping !== false
        || addtionalConfig?.config_value?.allow_header_mapping;
    let showProductCodeMapping = master?.config?.customer_portal_config?.show_product_code_mapping !== false;
    const customerProperties = master?.properties;
    let showAccountSetting = true;
    let showPluginOrders = true;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    if (enable_customer_config && customerProperties) {
        showHeaderMapping = (showHeaderMapping && (customerProperties?.header_mapping_settings));
        showProductCodeMapping = (showProductCodeMapping && (customerProperties?.product_code_mapping_settings));
        showAccountSetting = customerProperties?.account_settings;
        showPluginOrders = customerProperties?.plugin_orders;
    }
    const getInitialPage = (): keyof typeof SettingPages => {
        switch (history.location.pathname) {
            case `/${Routes.SETTINGS_HEADER}`:
                if (!showHeaderMapping) history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                return 'HeaderMapping';
            case `/${Routes.SETTINGS_PIECE_HEADER}`:
                if (!showHeaderMapping) history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                return 'PieceHeaderMapping';
            case `/${Routes.SETTINGS_PRODUCT}`:
                if (!showProductCodeMapping) history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                return 'ProductMapping';
            case `/${Routes.SETTINGS_CHANNEL}`:
                if (!(enable_shopify_integration && showPluginOrders)) history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                return 'ChannelIntegration';
            case `/${Routes.SETTINGS_ACCOUNT}`: return 'AccountSetting';
            default:
                if (!showProductCodeMapping) history.push(`/${Routes.SETTINGS_ACCOUNT}`);
                return 'ProductMapping';
        }
    };

    const [current, setCurrent] = React.useState<string>('ProductMapping');

    React.useEffect(() => {
        setCurrent(getInitialPage);
    }, [history.location.pathname]);

    const handlePageChange = (page: keyof typeof SettingPages) => {
        if (page === current) {
            return;
        }
        let nextPage = getHeaderMappingRoute();
        switch (page) {
            case 'ProductMapping': nextPage = getProductMappingRoute(); break;
            case 'AccountSetting': nextPage = getSettingsAccountRoute(); break;
            case 'ChannelIntegration': nextPage = getSettingsChannelRoute(); break;
            case 'PieceHeaderMapping': nextPage = getPieceHeaderMappingRoute(); break;
            case 'HeaderMapping':
            default: nextPage = getHeaderMappingRoute(); break;
        }
        setCurrent(page);
        history.push(nextPage);
    };

    const renderPages = () => {
        let settingKeys = Object.keys(SettingPages);
        if (!showHeaderMapping) {
            settingKeys = settingKeys.filter((key) => key !== 'HeaderMapping' && key !== 'PieceHeaderMapping');
        }
        if (!showProductCodeMapping) {
            settingKeys = settingKeys.filter((key) => key !== 'ProductMapping');
        }
        if (!showAccountSetting) {
            settingKeys = settingKeys.filter((key) => key !== 'AccountSetting');
        }
        if (!(enable_shopify_integration && showPluginOrders)) {
            settingKeys = settingKeys.filter((key) => key !== 'ChannelIntegration');
        }
        return settingKeys.map((key: any) => {
            return (
                <div
                    key={key}
                    onClick={() => handlePageChange(key)}
                    className={current === key ? classes.selected : classes.notSelected}
                >
                    {t(key)}
                </div>
            );
        });
    };

    const renderNavigation = () => {
        return (
            <div className={classes.navigation}>
                {renderPages()}
            </div>
        );
    };


    const renderAccountSettings = () => {
        if (current !== 'AccountSetting') {
            return null;
        }
        return (
            <AccountSettings />
        );
    };
    const renderChannelIntegration = () => {
        if (current !== 'ChannelIntegration' || (!(enable_shopify_integration && showPluginOrders))) {
            return null;
        }
        return (
            <ChannelIntegration />
        );
    };

    const renderProducts = () => {
        if (current !== 'ProductMapping') {
            return null;
        }
        return (
            <ProductMapping />
        );
    };

    const renderHeaders = () => {
        if (current !== 'HeaderMapping') {
            return null;
        }
        return (
            <HeaderMapping bannerHeight={bannerHeight} />
        );
    };

    const renderPieceHeaders = () => {
        if (current !== 'PieceHeaderMapping') {
            return null;
        }
        return (
            <HeaderMapping bannerHeight={bannerHeight} pieceHeaders />
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.content}>
                {renderAccountSettings()}
                {renderChannelIntegration()}
                {renderProducts()}
                {renderHeaders()}
                {renderPieceHeaders()}
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {renderNavigation()}
            {renderContent()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { master } = state;
    return {
        master,
    };
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: settingsStyles,
    },
};
export default GenericHoc(hocConfig)(SettingsIframe);

import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';

const styles = () => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    amount: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        '& .ant-input-group > .ant-input:first-child': {
            marginRight: 0,
        },
        '& .ant-select-selection-item, .ant-select-single.ant-select-show-arrow': {
            color: '#111111',
            fontSize: 12,
            width: 65,
            fontWeight: 600,
        },
    },
    label: {
        color: '#333333',
        fontSize: 12,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
}

const CODAmountField = (props: IProps) => {
    const { t } = useTranslation();
    const {
        classes,
        form,
        allowMultiplePieceDimensions,
        itemPartialDelivery,
        currencyListDomestic,
    } = props;
    const {
        ConsignmentCategory,
        IsCOD,
        Amount,
    } = formFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const isCodValue = Form.useWatch(IsCOD.key, form);

    if (consignmentCategory === 'international') return <></>;
    if (!isCodValue) return <></>;

    const renderCurrency = () => {
        if (consignmentCategory === 'international') return null;
        const options = currencyListDomestic.map((currency: string) => {
            return (
                <Select.Option key={currency} value={currency}>
                    {currency}
                </Select.Option>
            );
        });
        return (
            <Form.Item
                name="currency"
            >
                <Select
                    dropdownMatchSelectWidth={false}
                    placeholder="currency"
                >
                    {options}
                </Select>
            </Form.Item>
        );
    };

    return (
        <Col span={12}>
            <div className={classes.amount}>
                <span className={classes.label}>{t(Amount.key)}</span>
                <Form.Item
                    name={Amount.key}
                    initialValue={Amount.defaultValue}
                    valuePropName={Amount.valuePropName}
                    className={classes.amount}
                    rules={[{
                        validator: (rule, value, cb) => {
                            if (value < 0) {
                                return cb(t('negative_amount_error'));
                            }
                            if (value > 20000) {
                                return cb(t('cod_amount_limit_error'));
                            }
                            return cb();
                        },
                    }]}
                >
                    <Input
                        disabled={itemPartialDelivery && !allowMultiplePieceDimensions}
                        type="number"
                        placeholder={t('cod_amount_placeholder')}
                        addonAfter={renderCurrency()}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const consignmentConfig = config?.customer_portal_consignment_config;
    const itemPartialDelivery = config?.item_partial_delivery;
    const allowMultiplePieceDimensions = consignmentConfig?.allow_multiple_piece_dimensions;
    const currencyListDomestic = config.customer_portal_config?.cod_currency_list || ['INR'];
    return {
        itemPartialDelivery,
        allowMultiplePieceDimensions,
        currencyListDomestic,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(CODAmountField);

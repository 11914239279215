/* eslint-disable max-len */
import Cross from '../../assets/cross';
import {
    Drawer,
    Select,
    Spin,
    message,
    Input,
} from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../theme/jss-types';
import { locationSearchStyles } from './location-search.styles';
import { useTranslation } from 'react-i18next';
import { getAddressHierarchy, getLocationData } from 'network/common.api';
import { debounce } from 'lodash';
import Helper from 'library/Helper';
import { CopyOutlined } from '@ant-design/icons';

interface AddressHierarchy {
    id: string;
    name: string;
    location_key: string;
}

interface LocationData {
    id: string;
    name: string;
    pincode?: string;
    sample_pincode?: string;
    city?: string;
    state?: string;
    country?: string;
    [key: string]: string | undefined;
}

interface SearchLocation {
    searchType: string;
    queryString: string;
    hierarchyData?: any[];
    [key: string]: any;
}

interface LocationSearchProps extends StylesProps<ReturnType<typeof locationSearchStyles>> {
    onClose: () => void;
}

const LocationSearch = (props: LocationSearchProps) => {
    const {
        classes,
        onClose,
    } = props;
    const { t } = useTranslation();
    const [addressHierarchy, setAddressHierarchy] = React.useState<AddressHierarchy[]>([]);
    const [selectedValues, setSelectedValues] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    const [optionsLoading, setOptionsLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<any>([]);
    const [disabledFields, setDisabledFields] = React.useState<string[]>(['pincode', 'state', 'city']);

    React.useEffect(() => {
        if (selectedValues.country) {
            setDisabledFields(['pincode']);
        } else {
            setDisabledFields(['pincode', 'state', 'city']);
            selectedValues.state = undefined;
            selectedValues.city = undefined;
            selectedValues.pincode = undefined;
        }
    }, [selectedValues.country]);

    const placeholders: any = {
        country: 'Select the Country',
    };

    const loadaddressHierarchy = async () => {
        setLoading(true);
        try {
            const response = await getAddressHierarchy();
            if (response?.data) {
                setAddressHierarchy(response?.data);
            } else {
                setAddressHierarchy([]);
            }

            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch address hierarchy');
            setLoading(false);
        }
    };

    React.useEffect(() => {
        loadaddressHierarchy();
    }, []);

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('Location Search')}
                    </span>
                </div>
            </div>
        );
    };

    const handleSearch = debounce(async (queryString: string, locationKey: string) => {
        const locationIndex = addressHierarchy.findIndex((item: any) => Helper.caseInsensetiveMatch(item.location_key, locationKey));
        if (queryString.length < 3 && locationIndex !== addressHierarchy.length - 1) {
            return;
        }
        setOptionsLoading(true);
        const params: SearchLocation = {
            searchType: locationKey,
            queryString,
            hierarchyData: [],
        };

        if (locationIndex === -1) {
            return;
        }

        addressHierarchy.forEach((metadata: AddressHierarchy, index: number) => {
            if (index > locationIndex) {
                const key = metadata.location_key.toLowerCase();
                if (selectedValues[key]) {
                    params.hierarchyData?.push({
                        locationKey: key,
                        value: selectedValues[key],
                    });
                }
            }
        });

        try {
            const locationData = await getLocationData(params);
            if (locationData?.data) {
                setOptions(locationData.data);
            } else {
                setOptions([]);
            }
            setOptionsLoading(false);
        } catch (err) {
            setOptions([]);
            setOptionsLoading(false);
        }
    }, 500);

    const handleChangeSelection = (optionId: string, location_key: string) => {
        const addressIndex = addressHierarchy.findIndex((item: any) => Helper.caseInsensetiveMatch(item.location_key, location_key));
        if (addressIndex === -1) {
            return;
        }

        const selectedOption = options.find((option: any) => option.id === optionId);

        if (!selectedOption) {
            return;
        }

        const selectedValue = {
            [location_key.toLowerCase()]: selectedOption.name,
        };

        addressHierarchy.forEach((metadata: AddressHierarchy, index: number) => {
            if (index > addressIndex) {
                selectedValue[metadata.location_key.toLowerCase()] = selectedOption?.[metadata.location_key.toLowerCase()];
            }
            if (index < addressIndex) {
                selectedValue[metadata.location_key.toLowerCase()] = undefined;
            }
        });
        if (selectedOption.sample_pincode) {
            selectedValue.pincode = selectedOption.sample_pincode;
        } else {
            selectedValue.pincode = 'No Sample Available';
        }
        setSelectedValues(selectedValue);
    };

    const handleFocus = () => {
        setOptions([]);
    };

    const handleClear = (location_key: string) => {
        const selectedValue = selectedValues;
        selectedValue[location_key?.toLowerCase()] = undefined;
        setSelectedValues(selectedValue);
    };

    const renderOptionValue = (option: LocationData, location_key: string) => {
        const addressIndex = addressHierarchy.findIndex((item: any) => Helper.caseInsensetiveMatch(item.location_key, location_key));
        if (addressIndex === -1) {
            return '';
        }
        const valuesToShow = [option.name];
        addressHierarchy.forEach((metadata: {location_key: string}, index: number) => {
            const key = metadata.location_key.toLowerCase();
            if (index > addressIndex) {
                const value = option[key];
                if (value) {
                    valuesToShow.push(value);
                }
            }
        });
        return (
            <div className={classes.optionStyle}>
                <span>{valuesToShow[0]}</span>
                <span className={classes.optionInfo}>{` ${valuesToShow.slice(1).join(', ')}`}</span>
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div style={{ paddingTop: '100px' }}>
                {
                    addressHierarchy.slice().reverse().map((item: any) => {
                        return (
                            <div className={classes.locationField}>
                                <div className={classes.locationLabel}>
                                    {item.name === 'Pincode' ? 'Sample Pincode' : item.name}
                                </div>
                                <div
                                    className={classes.locationSelect}
                                >
                                    {item.name === 'Pincode'
                                        ? (
                                            <div style={{ width: '80%' }}>
                                                <Input
                                                    style={{ width: '100%' }}
                                                    value={selectedValues?.[item.location_key.toLowerCase()]}
                                                    className={classes.PincodeText}
                                                    onChange={(val) => handleChangeSelection(val.target?.value, item.location_key)}
                                                    disabled={disabledFields.map((field) => field.toLowerCase()).includes(item.location_key.toLowerCase())}
                                                    allowClear
                                                />
                                                <span
                                                    style={{
                                                        fontSize: '11px',
                                                        color: '#778899',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <b>Note: </b>
                                                    <i>This is the prescribed PIN Code format for this country. Please make sure to enter the correct PIN code during booking.</i>
                                                </span>
                                            </div>
                                        )
                                        : (
                                            <Select
                                                showSearch
                                                optionFilterProp="data-value"
                                                className={classes.locationSelect}
                                                value={selectedValues?.[item.location_key.toLowerCase()]}
                                                onSearch={(val) => handleSearch(val, item.location_key)}
                                                onChange={(val) => handleChangeSelection(val, item.location_key)}
                                                onClear={() => handleClear(item.location_key)}
                                                notFoundContent={optionsLoading ? <Spin size="small" /> : null}
                                                disabled={disabledFields.map((field) => field.toLowerCase()).includes(item.location_key.toLowerCase())}
                                                onFocus={handleFocus}
                                                allowClear
                                                placeholder={placeholders[item.location_key.toLowerCase()]}
                                            >
                                                {
                                                    options?.map((option: { id: string, name: string, pincode: string, city: string, state: string, country: string }) => (
                                                        <Select.Option {...option} value={option?.id} data-value={option.name}>
                                                            {renderOptionValue(option, item.location_key)}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    <div className={classes.copyButtonDiv}>
                                        <CopyOutlined
                                            className={selectedValues?.[item.location_key.toLowerCase()] ? classes.copyButton : classes.copyButtonDisabled}
                                            onClick={() => {
                                                navigator.clipboard.writeText(selectedValues?.[item.location_key.toLowerCase()] || '');
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    };

    const renderSpin = () => {
        return (
            <Spin
                style={{
                    marginLeft: '48%',
                    marginTop: '40%',
                }}
            />
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {
                loading ? renderSpin() : renderBody()
            }
        </Drawer>
    );
};

export default withStyles(locationSearchStyles)(LocationSearch);

import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '& .ant-input': {
            width: 100,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 10,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    label: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0,
        marginRight: 8,
    },
};

interface IProps extends WithStylesProps<typeof styles> {
    labelClass: string;
    value: string;
    onChange: (value: string) => void;
}

const CustomerReferenceNumber = (props: IProps) => {
    const {
        classes,
        labelClass,
        onChange,
        value,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.main}>
            <span className={labelClass || classes.label}>
                {t('customer_reference_number')}
            </span>
            <Input
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={t('enter_customer_reference_number')}
            />
        </div>
    );
};

const CustomerReferenceNumberFilter = withStyles(styles)(CustomerReferenceNumber);
export default CustomerReferenceNumberFilter;

import * as React from 'react';
import withStyles, { WithStylesProps } from 'react-jss';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 16,
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 100,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 10,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
    },
    label: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0,
        marginRight: 8,
    },
};
interface IProps extends WithStylesProps<typeof styles> {
    labelClass: string;
    value: string;
    onChange: (value: string) => void;
}
const Source = (props: IProps) => {
    const {
        classes,
        labelClass,
        onChange,
        value,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={classes.main}>
            <span className={labelClass || classes.label}>
                {t('plugin_source')}
            </span>
            <Select
                value={value}
                onChange={onChange}
                placeholder={t('select_source')}
                allowClear
            >
                {[
                    {
                        value: 'EXTERNAL CUSTOMER SHOPIFY INTEGRATION API',
                        label: 'Shopify',
                    },
                ].map((item) => {
                    return (
                        <Select.Option
                            key={item.value}
                            value={item.value}
                        >
                            {item.label}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
};

const PluginSourceFilter = withStyles(styles)(Source);
export default PluginSourceFilter;

import * as React from 'react';
import { addressBookStyles } from './address-book.styles';
import Paginator from '../common/paginator';
import {
    Button,
    message,
    Modal,
    Table,
    Input,
    Popconfirm,
    Dropdown,
    Menu,
} from 'antd';
import { EditOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { addressBookColumns } from './address-book.constants';
import {
    deleteAddress,
    bulkDeleteAddress,
    downloadAddresses,
    downloadAllAddresses,
} from '../../network/pickup.api';
import { ReduxStore } from '../../reducers/redux.types';
import { bindActionCreators } from 'redux';
import {
    setPagination,
} from '../../actions/generic-action';
import { Buckets, NextOrPrev, Pagination } from '../../library/Types';
import GenericHoc from '../common/generic-hoc';
import { RouteChildrenProps } from 'react-router';
import { NAVBAR_HEIGHT } from 'library/globals';
import CreateAddressForm from '../address/create-address';
import { PickupAddress } from '../pickup/create-pickup/pickup.types';
import Helper from '../../library/Helper';
import { loadAddresses } from '../../actions/address-actions';
import { useDebounce } from 'hooks/use-debounce';
import ExcelUpload from './excel-upload';
import { Master } from 'types/master-data-types';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

interface AddressBookProps
    extends StylesProps<ReturnType<typeof addressBookStyles>>,
    RouteChildrenProps {
    filters: Record<string, any>;
    loading: boolean;
    uiTheme: ThemeType;
    loadaddresses: (searchString: string) => void;
    pagination: Pagination,
    addressList: any,
    setpagination: (bucketId: Buckets.ADDRESSES, newPagination: Partial<Pagination>) => void;
    master: Master,
}

const {
    useState,
    useEffect,
} = React;

const AddressBookPage = (props: AddressBookProps) => {
    const [downloading, setDownloading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [newAddressVisible, setNewAddressVisible] = useState<boolean>(false);
    const [addressesSelected, setAddressesSelected] = useState<string[]>([]);
    const [editAddress, setEditAddress] = useState<PickupAddress | undefined>();
    const [searchString, setSearchString] = useState<string>('');
    const [toggle, setToggle] = useState<boolean>(true);
    const [excelUploadVisible, setExcelUploadVisible] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { t } = useTranslation();

    const {
        classes,
        loadaddresses,
        setpagination,
        loading,
        pagination,
        addressList,
        master,
        uiTheme,
    } = props;

    const phoneRegex = master?.parts_to_show?.remove_regex ? null : master?.config?.phone_regex;
    const countryWisePhoneRegex = master?.config?.country_wise_phone_regex;
    const debounceTimeSearchString = useDebounce(searchString);
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const showAddressCode = master?.config?.customer_portal_config?.allow_address_code_in_addresses;

    useEffect(() => {
        loadaddresses(searchString);
    }, [debounceTimeSearchString, toggle]);

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const openCreateAddressModal = () => {
        setNewAddressVisible(true);
    };

    const handleDownload = async () => {
        setDownloading(true);
        const selectedAddressToDownload = addressList?.addressBookData
            .filter((item: any) => addressesSelected.includes(item.id));

        const fileBuffer = await downloadAddresses(selectedAddressToDownload);

        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'addressbook.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
        setDownloading(false);
    };

    const handleDownloadAll = async () => {
        setDownloading(true);
        const fileBuffer = await downloadAllAddresses('');

        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'addressbook.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }

        setDownloading(false);
    };

    const newAddressMenu = (
        <Menu>
            <Menu.Item
                key="profile"
                onClick={() => openCreateAddressModal()}
            >
                {t('single_address')}
            </Menu.Item>
            <Menu.Item
                key="settings"
                onClick={() => setExcelUploadVisible(true)}
            >
                {t('upload_excel_address')}
            </Menu.Item>
        </Menu>
    );

    const downloadAddressMenu = (
        <Menu>
            <Menu.Item
                key="profile"
                onClick={() => handleDownloadAll()}
            >
                All
            </Menu.Item>
            <Menu.Item
                key="settings"
                onClick={() => handleDownload()}
            >
                Selected
            </Menu.Item>
        </Menu>
    );

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            setToggle(!toggle);
            message.success('Address Deleted Successfully');
        } else {
            message.error(result.errorMessage);
        }
    };

    const handleDeleteBulk = async (e: any, addressToDelete: string[]) => {
        Helper.stops(e);
        setDeleteLoading(true);
        const result = await bulkDeleteAddress({ data: addressToDelete });
        if (result.data.success) {
            setToggle(!toggle);
            message.success('Addresses Deleted Successfully');
        } else {
            message.error('Unable to delete the selected addresses');
        }
        setDeleteLoading(false);
    };

    const renderEditIcon = (item: PickupAddress, theme: ThemeType) => {
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    setEditAddress(item);
                    setNewAddressVisible(true);
                }}
                style={{ color: theme.primaryColor, marginRight: '0 8px' }}
            />
        );
    };

    const renderDeleteIcon = (item: PickupAddress) => {
        return (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onConfirm={(e) => handleDelete(e, item.id)}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: 'red' }}
                />
            </Popconfirm>
        );
    };

    const renderActions = (row: any) => {
        return (
            <div>
                {renderEditIcon(row, uiTheme)}
                {renderDeleteIcon(row)}
            </div>
        );
    };

    const renderColumn = (text: any, row: any, column: string) => {
        if (column === 'actions') {
            return renderActions(row);
        }
        if (typeof text === 'boolean') {
            return renderText(text ? 'Yes' : 'No');
        }
        if (!text || text === null) {
            return <div className={classes.cellNa}>—na—</div>;
        }
        return renderText(text);
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'name':
            case 'phone':
            case 'alternatePhone': return 120;
            case 'serialNumber': return 60;
            default: return 80;
        }
    };

    const getFixed = (column: string) => {
        switch (column) {
            case 'actions': return 'right';
            default: return undefined;
        }
    };

    const getColumns = (): any[] => {
        const addressTableColumns = showAddressCode
            ? addressBookColumns
            : omit(addressBookColumns, 'addressCode');
        const columns: any = Object.keys(addressTableColumns).map((column: string) => {
            return {
                key: column,
                title: t(column),
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: getFixed(column),
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                rowKey={(row) => row.id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                rowSelection={{
                    onSelect: (row, selected: any) => {
                        let newArray = [...addressesSelected];
                        if (selected && !newArray.includes(row.id)) {
                            newArray.push(row.id);
                        } else {
                            newArray = newArray.filter((item) => (item !== row.id));
                        }
                        setAddressesSelected(newArray);
                    },
                    onSelectAll: (selected, selectedRows) => {
                        const newArray = selectedRows.map((item) => (item.id));
                        setAddressesSelected(newArray);
                    },
                }}
                className={classes.table}
                dataSource={addressList?.addressBookData || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    const handleSearchQuery = (e : any) => {
        const newPagination = {
            ...pagination,
            currentPageNumber: 1,
        };
        setpagination(Buckets.ADDRESSES, newPagination);
        setSearchString(e.target.value);
    };

    const renderFilter = () => {
        return (
            <div className={classes.filter}>
                <Input
                    className={classes.searchInput}
                    type="search"
                    value={searchString}
                    onChange={(e: any) => handleSearchQuery(e)}
                    placeholder={t('search_for_addresses')}
                    suffix={<SearchOutlined />}
                />
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderFilter()}
            </div>
        );
    };

    const renderDownload = () => {
        return (
            <Button
                type="ghost"
                loading={downloading}
                className={classes.downloadBtn}
            >
                {t('download')}
            </Button>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.ADDRESSES, newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <div style={{ direction: 'ltr' }}>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={addressList?.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                />
            </div>
        );
    };

    const renderDeleteButton = () => {
        return (
            <Button
                type="ghost"
                loading={deleteLoading}
                className={classes.downloadBtn}
                onClick={(e) => handleDeleteBulk(e, addressesSelected)}
            >
                {t('delete_address')}
            </Button>
        );
    };

    const handleBulkEdit = () => {
        setExcelUploadVisible(true);
        setIsEdit(true);
    };

    const renderEditButton = () => {
        if (!showAddressCode) return null;
        return (
            <Button
                type="ghost"
                className={classes.downloadBtn}
                onClick={() => handleBulkEdit()}
            >
                {t('bulk_edit_address')}
            </Button>
        );
    };

    const renderNewAddress = () => {
        return (
            <Button
                type="primary"
                className={classes.newAddressBtn}
            >
                {t('new_address')}
            </Button>
        );
    };

    const cancelModal = () => {
        setNewAddressVisible(false);
        setEditAddress(undefined);
    };

    const renderCreateNewAddress = () => {
        if (!newAddressVisible) {
            return null;
        }
        return (
            <Modal
                className={classes.modal}
                closable
                visible
                title={editAddress?.id ? t('edit_address') : t('create_address')}
                width="700px"
                footer={null}
                onCancel={() => cancelModal()}
            >
                <CreateAddressForm
                    editData={editAddress}
                    onClose={() => {
                        cancelModal();
                        setToggle(!toggle);
                    }}
                    phoneRegex={phoneRegex}
                    isInternationalAddress={
                        (editAddress?.isInternational || false)
                    }
                    countryWisePhoneRegex={countryWisePhoneRegex}
                />
            </Modal>
        );
    };


    const renderCreateAddressDropdown = () => {
        return (
            <Dropdown
                overlay={newAddressMenu}
                trigger={['click']}
            >
                {renderNewAddress()}
            </Dropdown>
        );
    };

    const renderDownloadDropdown = () => {
        return (
            <Dropdown
                overlay={downloadAddressMenu}
                trigger={['click']}
            >
                {renderDownload()}
            </Dropdown>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderDeleteButton()}
                {renderEditButton()}
                {renderDownloadDropdown()}
                {renderCreateAddressDropdown()}
                {renderPaginator()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderExtraFilters()}
            </div>
        );
    };

    const onCloseDrawer = () => {
        setExcelUploadVisible(false);
        setIsEdit(false);
    };

    const renderExcelUpload = () => {
        if (!excelUploadVisible) {
            return null;
        }

        return (
            <ExcelUpload
                onClose={() => onCloseDrawer()}
                isEdit={isEdit}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderCreateNewAddress()}
            {renderExcelUpload()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { ADDRESSES } = generic;
    const {
        loading,
        data,
        pagination,
    } = ADDRESSES;
    return {
        loading,
        addressList: data,
        uiTheme: state.uiTheme,
        pagination,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadaddresses: loadAddresses,
        setpagination: setPagination,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: addressBookStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const AddressBooks = GenericHoc(hocConfig)(AddressBookPage);
export default AddressBooks;

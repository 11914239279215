export const VirtualInventoryColumns = [{
    column_id: 'serviceType',
    pretty_name: 'service_type',
    is_sortable: false,
    width: 246,
}, {
    column_id: 'start',
    pretty_name: 'start',
    is_sortable: false,
    width: 120,
}, {
    column_id: 'end',
    pretty_name: 'end',
    is_sortable: false,
    width: 120,
}, {
    column_id: 'counter',
    pretty_name: 'next_in_series',
    is_sortable: false,
    width: 120,
}, {
    column_id: 'type',
    pretty_name: 'type',
    is_sortable: false,
    width: 120,
}, {
    column_id: 'checkDigitLogic',
    pretty_name: 'Check Digit Logic',
    is_sortable: false,
    width: 120,
}, {
    column_id: 'availableCount',
    pretty_name: 'inventory_remaining',
    is_sortable: false,
    width: 120,
    textAlign: 'center',
}, {
    column_id: 'clientId',
    is_sortable: false,
    pretty_name: 'client_code',
    width: 120,
}, {
    column_id: 'createdAt',
    pretty_name: 'date_of_issue',
    is_sortable: false,
    width: 150,
}, {
    column_id: 'action',
    pretty_name: 'action',
    is_sortable: false,
    width: 80,
    textAlign: 'center',
}];

import * as React from 'react';
import Timeline from 'antd/lib/timeline';
import { courierTrackingStyles } from './consignment-details.styles';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { HocOptions } from '../common/generic-hoc.types';
import { TrackingEvent } from '../../library/Types';
import { StylesProps } from '../../theme/jss-types';
import Helper from '../../library/Helper';
import FallbackPage from '../common/fallback';
import GenericHoc from '../common/generic-hoc';
import { fetchConsignmentTracking } from '../../network/consignments.api';
import { Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import GalleryModal from '../common/gallery-modal';
import { Master } from 'types/master-data-types';
// import { allowedTrackingEvents } from '../../library/globals';

interface IProps extends StylesProps<ReturnType<typeof courierTrackingStyles>> {
    referenceNumber: string;
    dontShowTitle?: boolean;
    toggle?: boolean;
    trackType?: string;
    master: Master;
}

interface GalleryModalData {
    images: string[];
    videos: string[];
}

const ConsignmentTracking = (props: IProps) => {
    const {
        classes,
        referenceNumber,
        dontShowTitle,
        toggle,
        trackType,
        master,
    } = props;
    const { t, i18n } = useTranslation();
    const [tracking, setTracking] = React.useState<any>({});
    const [trackingResult, setTrackingResult] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [galleryModalData, setGalleryModalData] = React.useState<GalleryModalData>({ images: [], videos: [] });
    const [trackHeader, setTrackHeader] = React.useState<any>();
    const enable_edd = master?.config?.customer_portal_config?.enable_edd !== false;

    const loadTracking = async () => {
        setLoading(true);
        let customerReferenceNumber = '';
        if (trackType === 'Customer Reference No') {
            customerReferenceNumber = referenceNumber;
        }
        const trackingResponse = await fetchConsignmentTracking(
            { customerReferenceNumber, referenceNumber: customerReferenceNumber ? '' : referenceNumber },
        );
        const trackingData = (trackingResponse?.data?.events || []);
        const trackingHeader = trackingResponse?.data?.track_header;

        if (setTrackHeader) {
            setTrackHeader(trackingHeader);
        }
        setTrackingResult(trackingResponse);
        // trackingData = trackingData.filter((event: TrackingEvent) => {
        //     return allowedTrackingEvents.includes(event.strCode);
        // });
        setTracking(trackingData || []);
        setLoading(false);
    };

    React.useEffect(() => {
        loadTracking();
    }, [toggle]);

    const dot = (event: any) => {
        return (
            <CheckCircleOutlined
                className={classes.dot}
                style={{
                    color: event.type === 'DELIVERED' ? '#27B479' : '#006EC3',
                }}
            />
        );
    };

    const getDate = (input: string) => {
        if (!input) {
            return null;
        }
        return moment(input).format('DD MMM YYYY');
        // const date = input.substr(0, 2);
        // const month = input.substr(2, 2);
        // const year = input.substr(4);
        // return `${date}/${month}/${year}`;
    };

    const getTime = (input: string) => {
        if (!input) {
            return null;
        }
        return moment(input).format('hh:mm A');
        // let hrs = input.substr(0, 2);
        // const min = input.substr(2);
        // let amPm = 'AM';
        // if (Number(hrs) > 12) {
        //     const hrsFormated = Number(hrs) - 12;
        //     if (hrsFormated < 10) {
        //         hrs = `0${hrsFormated}`;
        //     } else {
        //         hrs = String(hrsFormated);
        //     }
        //     amPm = 'PM';
        // } else if (Number(hrs) === 12) {
        //     amPm = 'PM';
        // }
        // return `${hrs}:${min} ${amPm}`;
    };

    const lineitemLeft = (event: TrackingEvent) => {
        return (
            <div className={classes.lineItemLeft} style={{ width: '20%' }}>
                <div className={classes.date}>
                    {getDate(event.event_time || event.strActionDate)}
                </div>
                <div className={classes.time}>
                    {getTime(event.event_time || event.strActionTime)}
                </div>
            </div>
        );
    };

    const lineitemRight = (event: TrackingEvent) => {
        return (
            <div className={classes.lineItemRight}>
                <div className={classes.textRight}>
                    {event.strAction || (i18n.exists(event.type) ? t(event.type) : Helper.snakeToPretty(event.type))}
                </div>
                <div
                    className={classes.locationRight}
                    style={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {event.tracking_note}
                </div>
                <div
                    className={classes.locationRight}
                    style={{
                        alignSelf: 'flex-start',
                    }}
                >
                    {event.tracking_location_string || event.strOrigin || event.hub_name}
                </div>
                <div>
                    {
                        event.type === 'attempted'
                            ? (
                                <div>{event.failure_reason}</div>
                            )
                            : (<div />)
                    }

                    {
                        event.type === 'delivered' && (event.poc_image || event.premise_image)
                            ? (
                                <div>
                                    <div>
                                        <a onClick={() => {
                                            setIsGalleryModalVisible(true);
                                            setGalleryModalData({
                                                images: [event.poc_image || event.premise_image],
                                                videos: [],
                                            });
                                        }}
                                        >
                                            {t('tracking_view_pod')}
                                        </a>
                                    </div>
                                </div>
                            )
                            : (<div />)
                    }
                    {
                        event.type === 'delivered' && event.signature_image
                            ? (
                                <div>
                                    <a onClick={() => {
                                        setIsGalleryModalVisible(true);
                                        setGalleryModalData({
                                            images: [event.signature_image],
                                            videos: [],
                                        });
                                    }}
                                    >
                                        {t('tracking_view_customer_signature')}
                                    </a>
                                </div>
                            )
                            : (<div />)
                    }
                    {
                        ['delivered'].includes(event.type)
                            ? (
                                <>
                                    <div
                                        className={classes.locationRight}
                                        style={{
                                            alignSelf: 'flex-start',
                                        }}
                                    >
                                        OTP Verified:
                                        {event.otp_success ? ' Yes' : ' No' }
                                    </div>
                                </>
                            )
                            : (<div />)
                    }
                </div>
            </div>
        );
    };

    const timelineItem = (event: TrackingEvent, idx: number) => {
        const trackingSize = tracking?.length || 0;
        return (
            <div className={classes.flexRow}>
                {lineitemLeft(event)}
                <Timeline.Item
                    className={(idx === trackingSize - 1) ? classes.lineItemNoTail : classes.timelineItem}
                    dot={dot(event)}
                />
                {lineitemRight(event)}
            </div>
        );
    };

    const getTrackingItems = () => {
        const newTrackingItems: TrackingEvent[] = [];
        tracking.forEach((event: TrackingEvent) => {
            // const index = newTrackingItems.length - 1;
            // const lastEvent = index >= 0 ? newTrackingItems[index] : { strCode: null };
            // if (lastEvent.strCode !== event.strCode) {
            newTrackingItems.push(event);
            // } else {
            //     newTrackingItems[index] = event;
            // }
        });
        return newTrackingItems;
    };

    const timeline = () => {
        const trackingToShow = getTrackingItems();
        return (
            <Timeline className={classes.timeline}>
                {trackingToShow?.map((item: TrackingEvent, idx: number) => (timelineItem(item, idx)))}
            </Timeline>
        );
    };

    const renderTracking = () => {
        return (
            <div className={classes.trackingBox}>
                {timeline()}
            </div>
        );
    };

    const renderTitle = () => {
        if (dontShowTitle) {
            return null;
        }
        return (
            <div className={classes.trackingTitle}>
                {t('tracking_timeline')}
            </div>
        );
    };

    if (loading) {
        return (
            <Spin
                style={{
                    marginLeft: '48%',
                    marginTop: '40%',
                }}
            />
        );
    }

    if (!tracking.length) {
        if (trackingResult && !!trackingResult.errorMessage) {
            return <FallbackPage errorMsg={trackingResult.errorMessage} />;
        }
        return <FallbackPage errorMsg={t('tracking_fallback_message')} />;
    }

    const renderEddDates = () => {
        return (
            <div className={classes.trackText}>
                {(enable_edd && trackingResult?.data?.expected_delivery_date) || trackHeader?.edd_date ? (
                    <>
                        <span className={classes.availableText}>EDD</span>
                        <Tooltip title="Estimated Date of Delivery">
                            <InfoCircleOutlined className={classes.eddDescription} />
                        </Tooltip>
                        <span className={classes.referenceText}>
                            {(() => {
                                if (enable_edd && trackingResult?.data?.expected_delivery_date) {
                                    return getDate(trackingResult?.data?.expected_delivery_date);
                                } if (trackHeader?.edd_date) {
                                    return getDate(trackHeader.edd_date);
                                }
                                return 'NA';
                            })()}
                        </span>

                    </>
                ) : null}

                {(enable_edd && trackingResult?.data?.revised_expected_delivery_date) || trackHeader?.redd_date ? (
                    <>
                        <span className={classes.availableText}>REDD</span>
                        <span className={classes.referenceText}>
                            {(() => {
                                if (enable_edd) {
                                    return getDate(trackingResult?.data?.revised_expected_delivery_date);
                                } if (trackHeader?.redd_date) {
                                    return getDate(trackHeader.redd_date);
                                }
                                return 'NA';
                            })()}
                        </span>
                    </>
                ) : null}
            </div>

        );
    };

    return (
        <div className={classes.mainDiv}>
            {renderTitle()}
            {renderEddDates()}
            <div className={classes.hr} />
            {renderTracking()}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
        </div>
    );
};

const options: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: courierTrackingStyles,
    },
};

const Courier = GenericHoc(options)(ConsignmentTracking);
export default Courier;

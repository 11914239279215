import Cross from '../../../assets/cross';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import * as React from 'react';
import withStyles from 'react-jss';
import Helper from '../../../library/Helper';
import { StylesProps, ThemeType } from '../../../theme/jss-types';
import { bulkConsignmentStyles } from 'components/create-consignment/bulk-consignments.styles';
import { createBulkPickups, downloadSamplePickup } from 'network/pickup.api';
import { useTranslation } from 'react-i18next';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

interface BulkPickupProps extends StylesProps<ReturnType<typeof bulkConsignmentStyles>> {
    onClose: () => void;
    setFailure: (data: any) => void;
    success: () => void;
    uiTheme: ThemeType;
    config: {
        name: string;
        uploadName: string;
    };
}

const BulkPickup = (props: BulkPickupProps) => {
    const {
        classes,
        onClose,
        setFailure,
        success,
        config,
        uiTheme,
    } = props;

    const { t } = useTranslation();
    const [pickupArray, setPickup] = React.useState<any>([]);
    const [creating, setCreating] = React.useState<boolean>(false);

    const handleCreate = async () => {
        setCreating(true);
        const response = await createBulkPickups({
            pickupArray,
        });
        if (response.isSuccess) {
            if (response?.data?.failure?.length > 0) {
                setFailure(response?.data?.failure);
            } else {
                success();
                message.success(t('excel_upload_success'));
            }
            onClose();
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    };

    const renderButton = () => {
        let btnClass = classes.newButtonDisabled;
        if (pickupArray.length) {
            btnClass = classes.newButton;
        }
        return (
            <Button
                type="primary"
                loading={creating}
                className={btnClass}
                disabled={!pickupArray.length}
                style={{
                    border: `1px solid ${!pickupArray?.length ? '#EDEDED'
                        : uiTheme.primaryColor}`,
                }}
                onClick={handleCreate}
            >
                {t('submit')}
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.addText}>
                    <Cross onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                    <span>
                        {config.name}
                    </span>
                </div>
                {renderButton()}
            </div>
        );
    };

    const mapping: Record<string, string> = {
        'Client Code': 'clientCode',
        'Courier Type': 'courierType',
        'Total Items': 'totalItems',
        'Total Weight (KG)': 'totalWeight',
        'Pickup Slot Date': 'pickupSlotdate',
        'Pickup Slot Time': 'desiredPickupTime',
        'Pickup Name': 'pickupAddressname',
        'Pickup Phone': 'pickupAddressphone',
        'Pickup Pincode': 'pickupAddresspincode',
        'Pickup Address Line 1': 'pickupAddressaddressLine1',
        'Pickup Address Line 2': 'pickupAddressaddressLine2',
        'Pickup City': 'pickupAddresscityName',
        'Pickup State': 'pickupAddressstateName',
        'Pickup Hub Code': 'pickupHubCode',
    };

    const getPickupArray = (data: any[]) => {
        const headers = data[0];
        const slicedData = data.slice(1);
        const newData: any[] = slicedData.map((item) => {
            const returnObj: Record<string, any> = {};
            Object.keys(item).forEach((key: string) => {
                let keyUpdate = headers[key];
                keyUpdate = mapping[keyUpdate];
                returnObj[keyUpdate] = item[key];
            });
            return returnObj;
        });
        return newData;
    };

    const transformData = (data: any[]) => {
        const newData: any = {};
        newData.pickupArray = getPickupArray(data[0]);
        return newData;
    };

    const handleParsedData = async (data: any[]) => {
        if (!data || !data.length) return;
        const slicedData = transformData(data);
        setPickup(slicedData.pickupArray);
    };

    const onFileRecieved = async (file: any) => {
        const consignmentArr: any[] = await Helper.handleXLSXFile(file, Object.keys(mapping), 0);
        handleParsedData(consignmentArr);
    };

    const handleUploadChange = (info: UploadChangeParam) => {
        const { status } = info.file;
        if (status === 'done') {
            onFileRecieved(info.file.originFileObj);
        }
    };

    const customRequest = ({ onSuccess, file }: any) => {
        setTimeout(() => {
            onSuccess(null, file);
        }, 100);
    };

    const renderUpload = () => {
        return (
            <div
                className={classes.uploadBox}
            >
                <Dragger
                    multiple={false}
                    accept=".xlsx, .xls, .csv"
                    name="file"
                    disabled={pickupArray.length}
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                >
                    <p className={classes.uploadText}>
                        <UploadOutlined className={classes.closeIcon} />
                        {`Upload excel file to add ${config.uploadName}`}
                    </p>
                    <p className={classes.uploadHint}>
                        {t('drag_and_drop_text')}
                        or
                        <Button type="link">Browse</Button>
                        to choose file
                    </p>
                </Dragger>
            </div>
        );
    };

    const handleDownload = async () => {
        const fileBuffer = await downloadSamplePickup();
        const fileName = fileBuffer.filename;
        if (fileBuffer.isSuccess) {
            Helper.downloadFileData(
                fileBuffer.data,
                fileName || 'sample-pickup-upload.xlsx',
                true,
            );
            message.success('Downloaded Successfully');
        } else {
            message.error(fileBuffer.errorMessage);
        }
    };

    const renderDownload = () => {
        return (
            <Button
                type="link"
                className={classes.downloadBtn}
                onClick={handleDownload}
            >
                <DownloadOutlined />
                {t('download_sample_file')}
            </Button>
        );
    };

    return (
        <Drawer
            visible
            width="35%"
            title={renderHeader()}
            onClose={() => onClose()}
            className={classes.main}
            closable={false}
        >
            {renderUpload()}
            {renderDownload()}
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(bulkConsignmentStyles)(GenericHoc(hocConfig)(BulkPickup));

import moment from 'moment';
import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { fetchPluginOrders } from '../network/consignments.api';
import { ReduxStore } from '../reducers/redux.types';
import {
    endLoading,
    startLoading,
    setGenericData,
    setPagination,
} from './generic-action';

const bucket = Buckets.PLUGIN;
const dateFormat = 'YYYY-MM-DD';



export const loadPluginOrders = () => async (
    dispatch: Dispatch<any>,
    store: () => ReduxStore,
) => {
    dispatch(startLoading(bucket));

    const { generic } = store();
    const { PLUGIN } = generic;
    const { appliedFilters, pagination } = PLUGIN;

    const response = await fetchPluginOrders({
        // resultPerPage: pagination.resultPerPage,
        page_number: pagination.currentPageNumber,
        bucket: appliedFilters.bucketSelected,
        is_international: appliedFilters.ConsignmentType === 'International',
        from_date: moment(appliedFilters.startDate).format(dateFormat),
        to_date: moment(appliedFilters.endDate).format(dateFormat),
        source: appliedFilters.source,
        customer_reference_number: appliedFilters.customerReferenceNumber,
        service_type: appliedFilters.serviceType,
        store_name: appliedFilters.storeName,
    });
    if (response.isSuccess) {
        dispatch(setGenericData(bucket, response?.data.page_data || []));
        dispatch(setPagination(bucket, {
            isNextPresent: response.data?.is_next_present,
        }));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
